<template>
  <b-icon
    class="iconButton"
    :icon="getIsPlaying ? 'volume-mute-fill' : 'volume-up-fill'"
    @click="toggleSound()"
  ></b-icon>
</template>

<script>
import EventBus from "@/services/eventBus.js";
import { mapGetters } from "vuex";
export default {
  name: "HandlerSound",
  methods: {
    toggleSound() {
      EventBus.$emit("HANDLE_MUSIC");
    },
  },
  computed: {
    ...mapGetters(["getIsPlaying"]),
  },
};
</script>

<style scoped>
.iconButton {
  font-size: 1.5rem;
  margin: 0px 10px;
  cursor: pointer;
}
</style>
