<template>
  <div class="container-footer">
    <div class="container-item-footer">
      <ul>
        <li class="item-menu-footer" @click="$router.push({ name: 'Home' }).catch(() => {})">{{ $t("nav.home") }}</li>
        <!-- <li class="item-menu-footer">{{ $t("nav.products") }}</li> -->
        <li class="item-menu-no-link" >{{ $t("nav.services") }}</li>
        <li class="item-menu-footer-secondary" @click="goTo('tarot')">{{ $t("nav.tarot") }}</li>
        <li class="item-menu-footer-secondary" @click="goTo('meditacion')">{{ $t("nav.meditacion") }}</li>
        <li class="item-menu-footer-secondary" @click="goTo('registros')">{{ $t("nav.registros") }}</li>
        <li class="item-menu-footer" @click="$router.push({ name: 'QuienesSomos' }).catch(() => {})">{{ $t("nav.about") }}</li>
        <li class="item-menu-footer" @click="$router.push({ name: 'Contactanos' }).catch(() => {})">{{ $t("nav.contact") }}</li>
      </ul>
    </div>
    <div class="container-item-footer">
      <ul>
        <!-- <li class="item-redes-footer">
          <b-icon icon="phone" font-scale="1.5"></b-icon>
          <span style="margin-left: 20px">+57 031-5555555</span>
        </li>
        <li class="item-redes-footer">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              focusable="false"
              width="1em"
              height="1em"
              style="
                -ms-transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
                font-size: 150%;
              "
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path
                d="M12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01A9.816 9.816 0 0 0 12.04 2m.01 1.67c2.2 0 4.26.86 5.82 2.42a8.225 8.225 0 0 1 2.41 5.83c0 4.54-3.7 8.23-8.24 8.23c-1.48 0-2.93-.39-4.19-1.15l-.3-.17l-3.12.82l.83-3.04l-.2-.32a8.188 8.188 0 0 1-1.26-4.38c.01-4.54 3.7-8.24 8.25-8.24M8.53 7.33c-.16 0-.43.06-.66.31c-.22.25-.87.86-.87 2.07c0 1.22.89 2.39 1 2.56c.14.17 1.76 2.67 4.25 3.73c.59.27 1.05.42 1.41.53c.59.19 1.13.16 1.56.1c.48-.07 1.46-.6 1.67-1.18c.21-.58.21-1.07.15-1.18c-.07-.1-.23-.16-.48-.27c-.25-.14-1.47-.74-1.69-.82c-.23-.08-.37-.12-.56.12c-.16.25-.64.81-.78.97c-.15.17-.29.19-.53.07c-.26-.13-1.06-.39-2-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.12-.24-.01-.39.11-.5c.11-.11.27-.29.37-.44c.13-.14.17-.25.25-.41c.08-.17.04-.31-.02-.43c-.06-.11-.56-1.35-.77-1.84c-.2-.48-.4-.42-.56-.43c-.14 0-.3-.01-.47-.01z"
                fill="#ffffff"
              />
            </svg>
          </span>
          <span style="margin-left: 20px">+57 555 55555555</span>
        </li> -->
        <li class="item-redes-footer">
          <b-icon
            style="cursor: pointer;"
            icon="instagram"
            font-scale="1.5"
            @click="redirectTo('https://www.instagram.com/misty.oracle/')"
          ></b-icon>
          <span
            style="cursor: pointer;margin-left: 20px"
            @click="redirectTo('https://www.instagram.com/misty.oracle/')"
            >{{ $t("nav.followUsOnInstagram") }}</span
          >
        </li>
        <li class="item-redes-footer">
          <b-icon icon="envelope" font-scale="1.5"></b-icon>
          <span style="margin-left: 20px;"><a href="mailto:contact@mistyoracle.com" style="color: white;">contact@mistyoracle.com</a></span>
        </li>
      </ul>
    </div>
    <div class="container-item-footer">
      <!-- <p class="item-horarios-footer">{{ $t("nav.hoursOfOperation") }}</p>
      <p class="item-horarios-footer">
        {{
          $t("messages.monday") + "-" + $t("messages.friday") + ": 8am - 5:30pm"
        }}
      </p>
      <p class="item-horarios-footer">
        {{ $t("messages.saturday") + ": 8am - 5pm" }}
      </p> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterContainer",
  methods: {
    redirectTo(url) {
      window.open(url, "_blank");
    },
    goTo(service) {
      this.$router.push("/sesiones/" + service);
    },
  },
};
</script>

<style lang="scss" scoped>
/* footer */
.container-footer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 300px;
  background-color: var(--color-font-misty-oracle);
  align-items: flex-start;
  border-top: 2px solid var(--primary-misty-oracle);
}
.container-item-footer {
  width: 33%;
  padding: 30px;
}
.container-item-footer ul {
  padding: 0px;
  li {
    span {
      color: #ffffff;
      font-size: 1.2rem;
    }
  }
}

.item-menu-no-link{
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  list-style: none;
  font-size: 13px;
  padding: 3px 10px;
  height: 100%;
  align-items: center;
  transition: 0.5s all;
  color: #ffffff;
  letter-spacing: 3px;
  margin-bottom: 10px;
  font-size: 1rem;
}

.item-menu-footer {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  list-style: none;
  font-size: 13px;
  padding: 3px 10px;
  cursor: pointer;
  height: 100%;
  align-items: center;
  transition: 0.5s all;
  color: #ffffff;
  letter-spacing: 3px;
  margin-bottom: 10px;
  font-size: 1rem;
}
.item-menu-footer:hover {
  background-color: var(--primary-misty-oracle);
}

.item-menu-footer-secondary {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  list-style: none;
  font-size: 11px;
  padding: 3px 20px;
  cursor: pointer;
  height: 100%;
  align-items: center;
  transition: 0.5s all;
  color: #ffffff;
  letter-spacing: 3px;
  margin-bottom: 10px;
  font-size: 1rem;
}
.item-menu-footer-secondary:hover {
  background-color: var(--primary-misty-oracle);
}
.item-redes-footer {
  list-style: none;
  margin-bottom: 30px;
  text-align: initial;
  font-size: 15px;
  color: white !important;
}
.item-horarios-footer {
  margin-bottom: 20px;
  text-align: initial;
  font-size: 17px;
  color: white;
}
.item-horarios-footer:first-child {
  font-size: 20px;
  font-weight: bold;
}

@media (max-width: 1200px) {
  .container-item-footer {
    width: 100%;
    border-bottom: 1px solid white;
    padding: 10px;
  }
  .item-redes-footer {
    margin-bottom: 15px;
    text-align: initial;
    font-size: 15px;
    color: white;
  }
}
</style>
