<template>
  <div class="main-container">
    <div class="header-sticky">
      <header-top />
      <header-container />
    </div>    
    <router-view></router-view>
    <footer-container />
    <div ref="audioActivator"></div>
    <b-modal
      id="modal-car-shop"
      ref="modal-car-shop"
      :title="
        payloadModalCarShop
          ? payloadModalCarShop.producto.nombre.toUpperCase()
          : ''
      "
      centered
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <div class="container-data" v-if="payloadModalCarShop">
        <div
          class="container-descripcion-producto"
          style="
            font-size: 20px;
            margin-bottom: 18px;
            border-bottom: 1px solid #e4e4e4;
            padding-bottom: 20px;
          "
        >
          {{ payloadModalCarShop.producto.descripcion | laPrimeraMayuscula }}
        </div>
        <div
          class="container-presentacion"
          style="
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 18px;
            border-bottom: 1px solid #e4e4e4;
            padding-bottom: 20px;
          "
        >
          <div>
            <span> presentacion: </span>
            <span style="font-size: 20px; font-weight: 600">
              {{
                this.payloadModalCarShop.presentacion.presentacion
                  | laPrimeraMayuscula
              }}
            </span>
          </div>
          <div>
            <span> precio unitario: </span>
            <span style="font-size: 20px; font-weight: 600">
              {{ this.payloadModalCarShop.presentacion.precio | numero }} $
            </span>
          </div>
        </div>
        <div class="container-comentarios">
          <span>Observaciones</span>
          <b-form-textarea
            id="textarea"
            v-model="comentario"
            placeholder="Alguna petición especial..."
            rows="3"
            max-rows="6"
            class="mt-2 mb-3"
          ></b-form-textarea>
        </div>
        <div
          class="container-cantidad"
          style="
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div>
            <span> cantidad: </span>
            <b-form-spinbutton
              id="sb-inline"
              v-model="payloadModalCarShop.cantidad"
              inline
            ></b-form-spinbutton>
          </div>
          <div>
            <span> Total: </span>
            <span style="font-size: 30px"> {{ total | numero }} $ </span>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { Helpers } from "@/mixins/Helpers";
import HeaderTop from "@/components/HeaderTop";
import HeaderContainer from "@/components/HeaderMenu";
import FooterContainer from "@/components/FooterContainer";
import EventBus from "@/services/eventBus.js";
export default {
  name: "mainComponent",
  mixins: [Helpers],
  components: {
    "header-top": HeaderTop,
    "header-container": HeaderContainer,
    "footer-container": FooterContainer,
  },
  data() {
    return {
      payloadModalCarShop: null,
      comentario: "",
      player: new Audio(require("../assets/sounds/musicMeditationNuevo.mp3")),
      isPlaying: false,
      eventPassed: false,
    };
  },
  mounted() {    
    EventBus.$on("SHOW_MODAL_CAR_SHOP", (payLoad) => {
      this.payloadModalCarShop = { ...payLoad };
      this.comentario = "";
      this.$nextTick(() => {
        this.$bvModal.show("modal-car-shop");
      });
    });
    /* EventBus.$on("HANDLE_MUSIC", () => {
      console.log("handle music");
      if (this.isPlaying) {
        this.stop();
      } else {
        this.play();
      }
    });
    this.player.addEventListener(
      "ended",
      function () {
        this.currentTime = 0;
        this.play();
      },
      false
    );
    this.$el.addEventListener("click", () => {
      if (this.eventPassed === false) {
        this.eventPassed = true;
        this.play();
      }
    }); */
  },
  methods: {
    ...mapMutations(["setProductInCar", "setIsPlaying"]),
    resetModal() {},
    play() {
      try {
        this.player.play();
        this.isPlaying = true;
        this.setIsPlaying(true);
      } catch (error) {
        console.log(error);
      }
    },
    stop() {
      try {
        this.player.pause();
        this.isPlaying = false;
        this.setIsPlaying(false);
      } catch (error) {
        console.log(error);
      }
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      const dataForCar = {
        cantidad: this.payloadModalCarShop.cantidad,
        presentacion: {
          id: this.payloadModalCarShop.presentacion.id,
          precio: this.payloadModalCarShop.presentacion.precio,
          presentacion: this.payloadModalCarShop.presentacion.presentacion,
          presentacion_id:
            this.payloadModalCarShop.presentacion.presentacion_id,
          producto_id: this.payloadModalCarShop.presentacion.producto_id,
        },
        producto: {
          es_agrandable: this.payloadModalCarShop.producto.es_agrandable,
          consumo: this.payloadModalCarShop.producto.consumo,
          descripcion: this.payloadModalCarShop.producto.descripcion,
          id: this.payloadModalCarShop.producto.id,
          imagen: this.payloadModalCarShop.producto.imagen,
          ingredientes: this.payloadModalCarShop.producto.ingredientes,
          nombre: this.payloadModalCarShop.producto.nombre,
          subcategoria_id: this.payloadModalCarShop.producto.subcategoria_id,
        },
        comentario: this.comentario,
        internalCode: this.generarToken(),
        adiciones: [],
      };
      this.setProductInCar(dataForCar);
      this.$nextTick(() => {
        this.$bvModal.hide("modal-car-shop");
      });
    },
  },
  computed: {
    total() {
      return this.payloadModalCarShop
        ? this.payloadModalCarShop.cantidad *
            this.payloadModalCarShop.presentacion.precio
        : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  display: flex;
  flex-wrap: wrap;
}

.header-sticky{
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
}
</style>
