import axios from "axios";
export default class AuthService {
  login(email, password) {
    let data = {
      email,
      password      
    };
    return axios.post(process.env.VUE_APP_API_PUBLIC + "auth/login", data);
  }
  resetPassword(email) {
    let data = {
      email
    };
    return axios.put(process.env.VUE_APP_API_PUBLIC + "usuarios/reset-password", data);
  }
}
