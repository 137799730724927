<template>
  <div class="header-container">
    <div class="logo-header-container">
      <div
        class="container-image"
        @click="$router.push({ name: 'Home' }).catch(() => {})"
        style="cursor: pointer"
      >
        <img
          src="@/assets/logo_misty_oracle.jpg"
          alt="logo-misty-oracle"
          class="fijo-image"
        />
        <span class="logoTitle">Misty Oracle</span>
      </div>
    </div>
    <div class="menu-header-container">
      <b-icon
        v-b-toggle.sidebar-1
        icon="list"
        class="boton-menu-lateral"
        scale="2"
        style="color: var(--color-font-misty-oracle)"
      ></b-icon>
      <b-sidebar id="sidebar-1" title="Menu" shadow class="menu-lateral-public">
        <ul class="ul-menu-lateral">
          <li
            class="item-menu-lateral"
            @click="$router.push({ name: 'Home' }).catch(() => {})"
          >
            <div>
              {{ $t("nav.home") }}
            </div>
          </li>
          <!-- <li class="item-menu-lateral">
            <div>
              {{ $t("nav.products") }}
            </div>
          </li> -->
          <!-- <li class="item-menu-lateral">{{ $t("nav.services") }}</li> -->

          <b-dropdown
            size="md"
            right
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            style="height: 100%"
            class="test"
          >
            <template #button-content>
              <div class="item-menu-lateral-extra" style="width: 100%">
                {{ $t("nav.services") }}
              </div>
            </template>
            <b-dropdown-item-button @click="goTo('tarot')">{{
              $t("nav.tarot")
            }}</b-dropdown-item-button>
            <b-dropdown-item-button @click="goTo('meditacion')">{{
              $t("nav.meditacion")
            }}</b-dropdown-item-button>
            <b-dropdown-item-button @click="goTo('registros')">{{
              $t("nav.registros")
            }}</b-dropdown-item-button>
          </b-dropdown>

          <li
            class="item-menu-lateral"
            @click="$router.push({ name: 'QuienesSomos' }).catch(() => {})"
          >
            <div>
              {{ $t("nav.about") }}
            </div>
          </li>
          <li
            class="item-menu-lateral"
            @click="$router.push({ name: 'Contactanos' }).catch(() => {})"
          >
            <div>
              {{ $t("nav.contact") }}
            </div>
          </li>
          <li
            class="item-menu-lateral"
            @click="$router.push({ name: 'blog' }).catch(() => {})"
          >
            <div>Blog</div>
          </li>
          <!-- <li
            class="item-menu-lateral"
            @click="$router.push({ name: 'Registrate' }).catch(() => {})"
            v-if="!getToken"
          >
            <div>
              {{ $t("nav.register") }}
            </div>
          </li> -->
        </ul>
      </b-sidebar>
      <ul class="ul-menu">
        <li
          class="item-menu"
          @click="$router.push({ name: 'Home' }).catch(() => {})"
        >
          {{ $t("nav.home") }}
        </li>
        <!-- <li class="item-menu">
          {{ $t("nav.products") }}
        </li> -->

        <!-- <li class="item-menu" @click="showService = true">
          {{ $t("nav.services") }}
        </li> -->

        <b-dropdown
          size="lg"
          right
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
          style="height: 100%"
        >
          <template #button-content>
            <span class="item-menu">
              {{ $t("nav.services") }}
            </span>
          </template>
          <b-dropdown-item-button @click="goTo('tarot')">{{
            $t("nav.tarot")
          }}</b-dropdown-item-button>
          <b-dropdown-item-button @click="goTo('meditacion')">{{
            $t("nav.meditacion")
          }}</b-dropdown-item-button>
          <b-dropdown-item-button @click="goTo('registros')">{{
            $t("nav.registros")
          }}</b-dropdown-item-button>
        </b-dropdown>

        <li
          class="item-menu"
          @click="$router.push({ name: 'QuienesSomos' }).catch(() => {})"
        >
          {{ $t("nav.about") }}
        </li>
        <li
          class="item-menu"
          @click="$router.push({ name: 'Contactanos' }).catch(() => {})"
        >
          {{ $t("nav.contact") }}
        </li>
        <li
          class="item-menu"
          @click="$router.push({ name: 'blog' }).catch(() => {})"
        >
          Blog
        </li>
        <!-- <li
          class="item-menu"
          @click="$router.push({ name: 'Registrate' }).catch(() => {})"
          v-if="!getToken"
        >
          {{ $t("nav.register") }}
        </li> -->
      </ul>
    </div>
    <div class="avatar-container">
      <span
        @click="$router.push({ name: 'ShoppingCar' }).catch(() => {})"
        v-if="getItemInCar > 0"
      >
        <b-avatar
          icon="cart4"
          :badge="getItemInCar.toString()"
          variant="primary"
          badge-variant="dark"
          badge-top
          class="shop-car-icon"
        ></b-avatar>
      </span>
      <span v-b-modal.modal-prevent-closing>
        <b-icon
          id="login-button"
          icon="box-arrow-in-right"
          scale="2"
          style="color: var(--color-font-misty-oracle)"
          v-if="!getToken"
          class="ml-3 mr-3"
        ></b-icon>
        <b-tooltip
          v-if="!getToken"
          target="login-button"
          :title="this.$t('messages.login')"
          placement="left"
        >
          {{ this.$t("messages.login") }}
        </b-tooltip>
      </span>

      <b-dropdown
        size="lg"
        right
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
        v-if="getToken"
        style="height: 100%"
      >
        <template #button-content>
          <b-avatar size="3rem" variant="secondary"></b-avatar>
        </template>
        <b-dropdown-item-button
          @click="$router.push({ name: 'Profile' }).catch(() => {})"
        >
          <span style="font-weight: bold">{{
            getNombreUser | todasLasPrimerasMayusculas
          }}</span></b-dropdown-item-button
        >
        <!-- <b-dropdown-item-button
          @click="$router.push({ name: 'MisPedidos' }).catch(() => {})"
          >Mis Pedidos</b-dropdown-item-button
        > -->
        <!-- <b-dropdown-item-button
          @click="$router.push({ name: 'Profile' }).catch(() => {})"
          >{{ $t("messages.profile") }}</b-dropdown-item-button
        > -->
        <b-dropdown-item-button
          @click="$router.push({ name: 'UsersList' }).catch(() => {})"
          v-if="getToken && getRoleUser != 'public'"
          >{{ $t("messages.administration") }}</b-dropdown-item-button
        >
        <b-dropdown-item-button
          @click="
            $router
              .push({
                name:
                  getRoleUser != 'public'
                    ? 'AgendamientosAdmin'
                    : 'AgendamientosUser',
              })
              .catch(() => {})
          "
          v-if="getToken"
          >{{ $t("messages.mySchedules") }}</b-dropdown-item-button
        >
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item-button @click="setlogout">{{
          $t("messages.logout")
        }}</b-dropdown-item-button>
      </b-dropdown>

      <changer-locale />
      <!-- <handler-sound /> -->
    </div>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      :title="$t('messages.login')"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      :ok-title="$t('messages.accept')"
      :cancel-title="$t('messages.cancel')"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :label="$t('messages.email')"
          label-for="email-input"
          invalid-feedback="Email es requerido"
          :state="emailState"
        >
          <b-form-input
            id="email-input"
            type="email"
            v-model="email"
            ref="email"
            :state="emailState"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          :label="$t('messages.password')"
          label-for="password-input"
          invalid-feedback="Password es requerido"
          :state="passwordState"
        >
          <b-form-input
            id="password-input"
            v-model="password"
            :state="passwordState"
            required
            ref="password"
            :type="showPassword ? 'text' : 'password'"
          ></b-form-input>
        </b-form-group>
        <b-form-checkbox
          id="showPassword"
          v-model="showPassword"
          name="showPassword"
        >
          {{ $t("messages.showPassword") }}
        </b-form-checkbox>
        <p
          style="
            margin: 0px;
            margin-top: 20px;
            font-size: 15px;
            text-align: center;
          "
        >
          <span @click="showResetPassword" style="color: blue; cursor: pointer">
            {{ $t("messages.forgotYourPassword") }}
          </span>
          <span
            @click="showCreateAnAccount"
            class="ml-5"
            style="color: blue; cursor: pointer"
          >
            {{ $t("messages.createAnAccount") }}
          </span>
        </p>
      </form>
    </b-modal>
    <b-modal
      id="modal-reset-password"
      ref="modal-reset-password"
      :title="$t('messages.resetPassword')"
      @show="resetModalReset"
      @hidden="resetModalReset"
      @ok="handleOkReset"
      :ok-title="$t('messages.accept')"
      :cancel-title="$t('messages.cancel')"      
    >
      <form ref="formReset" @submit.stop.prevent="handleSubmitReset">
        <b-form-group
          :label="$t('messages.email')"
          label-for="email-input-reset"
          invalid-feedback="Email es requerido"
          :state="emailState"
        >
          <b-form-input
            id="email-input-reset"
            type="email"
            v-model="emailReset"
            ref="emailReset"
            :state="emailResetState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import AuthService from "@/services/auth.service.js";
import EventBus from "@/services/eventBus.js";
import ChangerLocale from "@/components/PublicComponents/ChangerLocale.vue";
import HandlerSound from "@/components/PublicComponents/HandlerSound.vue";

import { mapMutations, mapGetters } from "vuex";
export default {
  components: {
    "changer-locale": ChangerLocale,
    "handler-sound": HandlerSound,
  },
  name: "HeaderContainer",
  data() {
    return {
      authService: null,
      scroll: 0,
      email: "",
      emailState: null,
      password: "",
      passwordState: null,
      showPassword: false,
      emailResetState: null,
      emailReset: "",
      showService: false,
    };
  },
  created() {
    this.authService = new AuthService();
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...mapMutations(["setUserData", "logout"]),
    goTo(service) {
      this.$router.push("/sesiones/" + service);
    },
    setlogout() {
      this.logout();
      this.$router.push({ name: "Home" }).catch(() => {});
    },
    handleScroll(event) {
      this.scroll = event.target.scrollingElement.scrollTop;
    },
    checkFormValidity() {
      //const valid = this.$refs.form.checkValidity();
      const validEmail = this.$refs.email.checkValidity();
      const validPassword = this.$refs.password.checkValidity();
      this.emailState = validEmail;
      this.passwordState = validPassword;
      return validEmail && validPassword;
    },
    checkFormResetValidity() {
      //const valid = this.$refs.form.checkValidity();
      const validEmailReset = this.$refs.emailReset.checkValidity();
      this.emailState = validEmailReset;
      return validEmailReset;
    },
    resetModalReset() {
      this.emailReset = "";
      this.emailResetState = null;
    },
    handleOkReset(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmitReset();
    },
    resetModal() {
      this.email = "";
      this.password = "";
      this.emailState = null;
      this.passwordState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmitReset() {
      if (!this.checkFormResetValidity()) {
        return;
      }
      this.authService
        .resetPassword(this.emailReset)
        .then((result) => {
          if (result.data.status == 200) {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Reset",
              variant: "success",
              message: this.$t("messages.passwordCheckMail"),
            });
            this.$nextTick(() => {
              this.$bvModal.hide("modal-reset-password");
            });
          } else {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "danger",
              message: this.$t("messages.errorResetPassword"),
            });
          }
        })
        .catch((err) => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: this.$t("messages.errorResetPassword"),
          });
        });
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.authService
        .login(this.email, this.password)
        .then((result) => {
          if (result.data.status == 200) {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: this.$t("messages.welcome"),
              variant: "success",
              message: `${this.$t(
                "messages.welcome"
              )}, ${result.data.data.nombres.toUpperCase()}`,
            });

            if (result.data.data.telefono && result.data.data.telefono.toString().length > 0 && result.data.data.telefono.toString()[0] != "+") {
              result.data.data.telefono = "+" + result.data.data.telefono.toString();
            }

            this.setUserData(result.data.data);
            this.$nextTick(() => {
              this.$bvModal.hide("modal-prevent-closing");
            });

            if(this.$router.currentRoute.name == "Registrate") {
              this.$router.push({ name: "Home" }).catch(() => {});
            }

          } else {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "danger",
              message: this.$t("messages.wrongData"),
            });
          }
        })
        .catch((err) => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: this.$t("messages.loginError"),
          });
          console.log("err", err);
        });
    },
    showResetPassword() {
      this.resetModal();
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
        this.$bvModal.show("modal-reset-password");
      });
    },
    showCreateAnAccount() {
      this.resetModal();
      this.$router.push({ name: "Registrate" }).catch(() => {});
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
  },
  computed: {
    ...mapGetters([
      "getToken",
      "getItemInCar",
      "getRoleUser",
      "getNombreUser",
      "getUserData",
    ]),
  },
};
</script>

<style lang="scss" scoped>
/* cabecera */
.header-container {
  height: 90px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  //border-bottom: 1px solid var(--primary-misty-oracle);
  /* box-shadow: 0 1px 5px rgb(0 0 0 / 10%); */
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
.fijo {
  position: sticky;
  top: 50px;
}
.logo-header-container,
.menu-header-container,
.avatar-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: white;
}
.logo-header-container {
  padding-left: 30px;
  width: 20%;
  height: 100%;
  justify-content: center;
}
.logo-image {
  height: 150px;
  z-index: 999;
  margin-top: 13px;
  transition: 0.5s all;
}
.fijo-image {
  height: 100%;
  z-index: 1;
  margin-top: 0px;
}
.menu-header-container {
  width: 60%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.ul-menu {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
}
.item-menu {
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  list-style: none;
  font-size: 1.2rem;
  padding: 0px 20px;
  cursor: pointer;
  height: 100%;
  align-items: center;
  transition: 0.5s color;
  color: var(--color-font-misty-oracle);
  letter-spacing: 3px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}
.item-menu:hover {
  color: var(--primary-misty-oracle);
  border-bottom: 4px solid var(--primary-misty-oracle);
  padding: 4px 20px 0px 20px;
}
.avatar-container {
  width: 20%;
  height: 100%;
  justify-content: flex-end;
  padding-right: 20px;
}

/* menu lateral */
.ul-menu-lateral {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.ul-menu-lateral {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
}
.boton-menu-lateral {
  display: none;
}
.menu-lateral-public {
  width: 30px !important;
}
.item-menu-lateral {
  list-style: none;
  font-weight: 600;
  padding: 0.375rem 0.75rem;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  div {
    border-bottom: 1px solid #b3b3b3;
  }
}
.item-menu-lateral-extra {
  font-weight: 600;
  border-bottom: 1px solid #b3b3b3;
  color: var(--color-font-misty-oracle);
}
.item-menu-lateral:hover,
.item-menu-lateral-extra:hover {
  background-color: var(--background-misty-oracle);
}
.big {
  margin-top: -11px;
}

.shop-car-icon {
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.container-image {
  /* padding: 30px; */
  /* z-index: 9999; */
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute;
  top: 0px;
  left: 150px;
  height: 200px;
  width: 200px; */
  /* box-shadow: 0 7px 21px rgba(0, 0, 0, 0.1); */
  height: 100%;
}

.container-image-fijo {
  /* padding: 0px;
  position: absolute;
  top: 0px;
  left: 100px;
  height: 100%;
  width: auto; */
  /* box-shadow: 0 7px 21px rgba(0, 0, 0, 0.1); */
}

.logoTitle {
  font-family: "Christmas-Day", cursive;
  font-size: 1.9rem;
}

@media (max-width: 1475px) {
  .logoTitle {
    font-size: 1rem;
  }
}

@media (max-width: 1370px) {
  .item-menu {
    font-size: 16px;
    letter-spacing: 0px;
  }
}

@media (max-width: 1100px) {
  .ul-menu {
    display: none;
  }
  .boton-menu-lateral {
    display: block;
  }
  .logo-header-container {
    width: 40%;
    padding: 0px;
  }
  .avatar-container {
    width: 40%;
    padding: 0px;
  }
  .menu-header-container {
    width: 20%;
  }
}

@media (max-width: 733px) {
  .logo-image {
    height: 80%;
    z-index: 1;
    margin-top: 0px;
    transition: 0.5s all;
  }
}

@media (max-width: 640px) {
  .container-image {
    padding: 10px;
    position: relative;
    height: 100%;
    left: 0;
    z-index: 1;
    box-shadow: none;
  }
  /* .container-image-fijo {
    left: 10px;
    z-index: 1;
  } */
}
</style>
