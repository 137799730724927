<template>
  <div class="top-header-container">
    <div class="container-top-telefonos">
      <!-- <b-icon icon="phone" font-scale="1.5"></b-icon>
      <span>+57 031 5555555</span>
      <span style="margin-left: 30px">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          focusable="false"
          width="1em"
          height="1em"
          style="
            -ms-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
            font-size: 150%;
          "
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            d="M12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01A9.816 9.816 0 0 0 12.04 2m.01 1.67c2.2 0 4.26.86 5.82 2.42a8.225 8.225 0 0 1 2.41 5.83c0 4.54-3.7 8.23-8.24 8.23c-1.48 0-2.93-.39-4.19-1.15l-.3-.17l-3.12.82l.83-3.04l-.2-.32a8.188 8.188 0 0 1-1.26-4.38c.01-4.54 3.7-8.24 8.25-8.24M8.53 7.33c-.16 0-.43.06-.66.31c-.22.25-.87.86-.87 2.07c0 1.22.89 2.39 1 2.56c.14.17 1.76 2.67 4.25 3.73c.59.27 1.05.42 1.41.53c.59.19 1.13.16 1.56.1c.48-.07 1.46-.6 1.67-1.18c.21-.58.21-1.07.15-1.18c-.07-.1-.23-.16-.48-.27c-.25-.14-1.47-.74-1.69-.82c-.23-.08-.37-.12-.56.12c-.16.25-.64.81-.78.97c-.15.17-.29.19-.53.07c-.26-.13-1.06-.39-2-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.12-.24-.01-.39.11-.5c.11-.11.27-.29.37-.44c.13-.14.17-.25.25-.41c.08-.17.04-.31-.02-.43c-.06-.11-.56-1.35-.77-1.84c-.2-.48-.4-.42-.56-.43c-.14 0-.3-.01-.47-.01z"
            fill="#ffffff"
          />
        </svg>
      </span>
      <span>+57 311 8842849</span> -->
    </div>
    <div class="container-top-offer"></div>
    <div class="container-top-redes">
      <b-icon
        style="cursor: pointer"
        icon="instagram"
        font-scale="1.5"
        @click="redirectTo('https://www.instagram.com/misty.oracle/')"
      ></b-icon>
      <span
        style="cursor: pointer"
        class="text-data"
        @click="redirectTo('https://www.instagram.com/misty.oracle/')"
        >{{ $t("messages.followUsOnInstagram") }}</span
      >
      <!-- <b-icon icon="phone" font-scale="1.5" style="margin-left: 30px"></b-icon>
      <span class="text-data">+57 031 5555555</span> -->
      <!-- <span style="margin-left: 30px">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          focusable="false"
          width="1em"
          height="1em"
          style="
            -ms-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
            font-size: 150%;
          "
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            d="M12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01A9.816 9.816 0 0 0 12.04 2m.01 1.67c2.2 0 4.26.86 5.82 2.42a8.225 8.225 0 0 1 2.41 5.83c0 4.54-3.7 8.23-8.24 8.23c-1.48 0-2.93-.39-4.19-1.15l-.3-.17l-3.12.82l.83-3.04l-.2-.32a8.188 8.188 0 0 1-1.26-4.38c.01-4.54 3.7-8.24 8.25-8.24M8.53 7.33c-.16 0-.43.06-.66.31c-.22.25-.87.86-.87 2.07c0 1.22.89 2.39 1 2.56c.14.17 1.76 2.67 4.25 3.73c.59.27 1.05.42 1.41.53c.59.19 1.13.16 1.56.1c.48-.07 1.46-.6 1.67-1.18c.21-.58.21-1.07.15-1.18c-.07-.1-.23-.16-.48-.27c-.25-.14-1.47-.74-1.69-.82c-.23-.08-.37-.12-.56.12c-.16.25-.64.81-.78.97c-.15.17-.29.19-.53.07c-.26-.13-1.06-.39-2-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.12-.24-.01-.39.11-.5c.11-.11.27-.29.37-.44c.13-.14.17-.25.25-.41c.08-.17.04-.31-.02-.43c-.06-.11-.56-1.35-.77-1.84c-.2-.48-.4-.42-.56-.43c-.14 0-.3-.01-.47-.01z"
            fill="#ffffff"
          />
        </svg>
      </span>
      <span class="text-data">+57 311 8842849</span> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderTop",
  methods: {
    redirectTo(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
/* top cabecera */
.top-header-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: var(--primary-misty-oracle);
  color: white;
}
.container-top-telefonos,
.container-top-offer,
.container-top-redes {
  display: flex;
  flex-wrap: wrap;
  height: 50px;
  width: 50%;
  padding: 0px 60px;
  align-items: center;
  justify-content: flex-end;
}
.container-top-offer {
  display: none;
}
.container-top-telefonos span {
  margin-left: 10px;
  font-size: 17px;
  color: white;
}
.container-top-redes span {
  margin-left: 10px;
  font-size: 17px;
  color: white;
}
@media (max-width: 940px) {
  .container-top-telefonos,
  .container-top-offer,
  .container-top-redes {
    width: 100%;    
  }
  .container-top-telefonos,
  .text-data {
    display: none;
  }
}
@media (max-width: 470px) {
  .container-top-telefonos,
  .container-top-offer,
  .container-top-redes {
    padding: 0px 10px;
  }
}

@media (max-width: 350px) {
  .container-top-telefonos span {
    font-weight: 500;
    margin-left: 10px;
    font-size: 12px;
  }
  .container-top-redes span {
    font-weight: 500;
    margin-left: 10px;
    font-size: 12px;
  }
}
</style>
